import React, { useState, useEffect, useRef } from "react";
import Spinner from "../UI/Spinner/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faSync,
  faFileImport,
  faFileExport,
} from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import "./Hostname.less";
import AddHostnameModal from "./AddHostnameModal";
import _ from "lodash";
import ConfirmModal from "../UI/ConfirmModal/ConfirmModal";
import { useConfig } from "../../contexts/configContext";
import useHttpApi from "../useHttpApi";
import {
  isNonEmptyArray,
  isNonEmptyString,
  removeAllButFields,
  uuidFromCode,
} from "../../utils";
import ListTable from "../SharedComponents/ListTable";
import { useSnackbar } from "../../contexts/snackbarContext";
import { checkHostnameImportedData } from "../../importErrors";

const Hostname = (props) => {
  const [loading, setloading] = useState(true);
  const [hostnames, sethostnames] = useState([]);
  const [hostnameModal, sethostnameModal] = useState(false);
  const [fetchedData, setfetchedData] = useState(null);
  const [openConfirmModal, setopenConfirmModal] = useState(false);
  const [fileToBeUploaded, setfileToBeUploaded] = useState(null);
  const [uploadErrors, setuploadErrors] = useState([]);
  const [tableFilters, settableFilters] = useState(null);

  const config = useConfig();
  const httpApi = useHttpApi();
  const snackbar = useSnackbar();

  const inputRef = useRef(null);

  const header = [
    {
      title: "Type",
      field: "type",
      size: "20%",
    },
    {
      title: "Hostname",
      field: "hostname",
      size: "40%",
    },
    {
      title: "Address",
      field: "address",
      size: "20%",
    },
    {
      title: "NF Type",
      field: "nfType",
      size: "15%",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "delete",
    },
    {
      title: "",
      field: "",
      size: "3%",
      type: "show",
    },
  ];

  useEffect(() => {
    props.setBreadcrumb("", "Service Communication Proxy", "");
    // setloading(false) // REMOVE THIS LINE AND UNCOMMENT THE FOLLOWING
  }, []);

  //Creating  Redis key, if necessary
  const createRedisKey = (key, data) => {
    httpApi.callPostAPI({ url: key, data, snack: { show: false } });
  };

  const needToCreateRedisKey = async () => {
    return Promise.all([
      httpApi.callGetAPI({
        url: "json/hostname",
        snack: { show: false },
        errorCallback: (err) => {
          err.response.status === 404 && createRedisKey("json/hostname", []);
          return "hostname";
        },
        successCallback: (data) => 1,
      }),
    ]);
  };

  useEffect(() => {
    needToCreateRedisKey().then((data) => {
      console.log(data);
      if (data.filter((el) => el !== 1).length > 0)
        snackbar.setSnackbarInfo({
          status: "warning",
          message: `The following requested <b>Redis keys</b> are missing and will be created: <i>${data.join(
            ", "
          )}</i>`,
        });
    });
  }, []);

  useEffect(() => {
    if (fetchedData == null) {
      const data = fetchData();
      data.then((res) => setfetchedData(res));
    }
  }, []);

  useEffect(() => {
    if (fetchedData) {
      setloading(false);
      sethostnames([...fetchedData.discovered, ...fetchedData.hostnames]);
    }
  }, [fetchedData]);

  const fetchData = async () => {
    let hostnames = await httpApi.callGetAPI({ url: "json/hostname" });
    hostnames = isNonEmptyArray(hostnames) ? hostnames : [];
    hostnames = hostnames.map((hn) => {
      hn.type = "Manual";
      hn.deleteable = true;
      return hn;
    });
    let discovered = await httpApi.callGetAPI({
      url: `json/${config.configInfo.protectionGroupID}discovered-hostname`,
      snack: {
        warning: {
          status: "warning",
          message: `The requested <i>${config.configInfo.protectionGroupID}discovered-hostname</i> Redis key does not exist`,
        },
      },
    });
    return {
      discovered: addUsefullParamsToDiscovered(discovered),
      hostnames: normalizeRows(header, hostnames),
    };
  };

  const normalizeRows = (header, jsonArray) => {
    header.forEach((h) => {
      jsonArray.forEach((json) => {
        if (h.field !== "" && !json.hasOwnProperty(h.field)) json[h.field] = "";
      });
    });
    return jsonArray;
  };

  const addUsefullParamsToDiscovered = (_discovered) => {
    _discovered = isNonEmptyArray(_discovered) ? _discovered : [];
    _discovered = _discovered.map((disc) => {
      disc.type = "Discovered";
      disc.deleteable = false;
      return disc;
    });

    return _discovered;
  };

  const hostnameModalShow = () => {
    sethostnameModal(true);
  };

  const hostnameModalHide = () => {
    sethostnameModal(false);
  };

  const addHostname = async (hostname) => {
    let tmpHostname = [...hostnames, hostname].filter(
      (h) => h.type === "Manual"
    );
    console.log([...hostnames, hostname]);
    tmpHostname.forEach((h) => {
      if (h.hasOwnProperty("deleteable")) delete h.deleteable;
    });

    console.log(tmpHostname);
    await httpApi.callPostAPI({
      url: "json/hostname",
      data: tmpHostname,
      snack: {
        success: { status: "success", message: "Hostname correctly added" },
      },
      successCallback: async () => {
        const data = await fetchData();
        sethostnames([...data.discovered, ...data.hostnames]);
      },
    });
  };

  const deleteHostname = async (row) => {
    const tmpHostnames = hostnames.filter(
      (h) => h.id !== row.id && h.type === "Manual"
    );

    await httpApi.callPostAPI({
      url: "json/hostname",
      data: tmpHostnames,
      snack: {
        success: { status: "success", message: "Hostname correctly deleted" },
      },
      successCallback: async () => {
        const data = await fetchData();
        sethostnames([...data.discovered, ...data.hostnames]);
      },
    });
  };

  const sendRedis = async () => {
    const obj = Object.assign(
      {},
      ...hostnames
        .filter((hostname) => hostname.type === "Manual")
        .map((hostname) => ({
          [hostname.hostname]: hostname.address,
        }))
    );
    await httpApi.callPostAPI({
      url: `json/${config.configInfo.protectionGroupID}hostname-table`,
      data: obj,
      snack: {
        success: {
          status: "success",
          message: "Hostname Table successfully pushed",
        },
      },
      successCallback: async () => {
        const data = await fetchData();
        sethostnames([...data.discovered, ...data.hostnames]);
      },
    });
  };

  const importFile = () => {
    inputRef.current.click();
  };

  const handleOnChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setfileToBeUploaded(e.target.files[0]);
      e.target.value = "";
    }
  };

  const uploadTable = () => {
    const reader = new FileReader();
    reader.onload = (e) => onReaderLoad(e);
    reader.readAsText(fileToBeUploaded);
  };

  const onReaderLoad = async (e) => {
    let { data, errors } = checkHostnameImportedData(e.target.result);
    errors = Array.from(errors);

    if (errors.length > 0) {
      setuploadErrors(errors);
      return;
    } else setuploadErrors([]);

    const json = JSON.stringify(data, null, 2);
    const blob = new Blob([json], { type: "application/json" });

    let formData = new FormData();
    formData.append("file", blob);
    await httpApi.callUploadAPI({
      url: "file/hostname",
      formData,
      snack: {
        success: {
          status: "success",
          message: "Hostname Table successfully uploaded",
        },
      },
      successCallback: async () => {
        const data = await fetchData();
        sethostnames([...data.discovered, ...data.hostnames]);
      },
    });
  };

  const exportFile = async () => {
    const fileName = `hostnameTable`;

    let fileContent = await httpApi.callGetAPI({
      url: `file/hostname`,
      snack: {
        show: false,
      },
    });

    fileContent = removeAllButFields(
      ["hostname", "address", "nfType"],
      fileContent
    );
    fileContent = httpApi.completeDownload(
      fileContent,
      {
        snack: {
          success: {
            status: "success",
            message: "Hostname file successfully exported",
          },
        },
      },
      { type: "json", name: fileName }
    );
  };

  return (
    <>
      <Spinner show={loading} />
      <ConfirmModal
        bsSize="medium"
        show={openConfirmModal}
        onHide={() => setopenConfirmModal(false)}
        onClose={() => setopenConfirmModal(false)}
        title="Upload "
        className="text-center"
        onAccept={(e) => {
          setopenConfirmModal(false);
          uploadTable();
        }}
        closeText="Close"
      >
        <div>
          <p>
            This action will permanently replace the current redis key with the
            one you are providing in the file!
          </p>
          <p>Do you want to continue?</p>
        </div>
      </ConfirmModal>
      {!loading && (
        <div className="page">
          <AddHostnameModal
            show={hostnameModal}
            onHide={hostnameModalHide}
            addHostname={addHostname}
            hostnames={hostnames}
          />
          <div className="hostname-panel">
            {hostnames.length > 0 ? (
              <ListTable
                elements={hostnames}
                filters={tableFilters}
                header={header}
                sortable
                searchable
                collapsible
                dense
                cellActions={[
                  {
                    elementName: "hostname",
                    type: "delete",
                    method: deleteHostname,
                    size: header.find(
                      (h) => h.hasOwnProperty("type") && h.type === "delete"
                    )?.size,
                  },
                  {
                    elementName: "hostname",
                    type: "show",
                    size: header.find(
                      (h) => h.hasOwnProperty("type") && h.type === "show"
                    )?.size,
                  },
                ]}
              ></ListTable>
            ) : (
              <h3 className="no-table-data">No data</h3>
            )}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ position: "absolute", zIndex: "100001" }}>
                  Add Hostname
                </Tooltip>
              }
            >
              <div
                onClick={() => hostnameModalShow()}
                className="addBtn action hostnameBtnPosition first"
              >
                <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ position: "absolute", zIndex: "100001" }}>
                  {hostnames ? "Send To Redis" : "Send Empty Table To Redis"}
                </Tooltip>
              }
            >
              <div
                onClick={() => sendRedis()}
                className="addBtn send action hostnameBtnPosition second"
              >
                <FontAwesomeIcon icon={faSync}></FontAwesomeIcon>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ position: "absolute", zIndex: "100001" }}>
                  Import Hostnames From JSON File
                </Tooltip>
              }
            >
              <div
                onClick={() => importFile()}
                className="addBtn import action hostnameBtnPosition third"
              >
                <input
                  ref={inputRef}
                  type="file"
                  id="jsonUpload"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleOnChange(e);
                    setopenConfirmModal(true);
                  }}
                />
                <FontAwesomeIcon icon={faFileImport}></FontAwesomeIcon>
              </div>
            </OverlayTrigger>

            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip style={{ position: "absolute", zIndex: "100001" }}>
                  Export Hostnames To JSON File
                </Tooltip>
              }
            >
              <div
                onClick={() => exportFile()}
                className="addBtn export action hostnameBtnPosition fourth"
              >
                <FontAwesomeIcon icon={faFileExport}></FontAwesomeIcon>
              </div>
            </OverlayTrigger>
            {uploadErrors.length > 0 && (
              <div className="upload-errors">
                <span className="errors-title">
                  Some errors have been found on your file!
                </span>
                {uploadErrors.map((err) => (
                  <div>- {err}.</div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export { Hostname };
