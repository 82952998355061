import React, { useEffect } from "react";
import {
  OverlayTrigger,
  Tooltip,
  Dropdown,
  DropdownButton,
  ButtonGroup,
  Form,
  Button,
  InputGroup,
  Tab,
  Tabs,
} from "react-bootstrap";
import { useConfig } from "../../contexts/configContext";
import CustomDashboard from "./CustomDahsboard";
import { useState } from "react";
import CrossedInput from "../SharedComponents/CrossedInput";
import useHttpApi from "../useHttpApi";

const Dashboard = (props) => {
  const config = useConfig();
  const namespace = config.namespace;
  const httpApi = useHttpApi();

  const [rangeTime, setrangeTime] = useState("6");
  const [metric, setmetric] = useState({ value: "h", label: "Hours" });
  const [currentDashboard, setcurrentDashboard] = useState(
    `${config.configInfo.grafanaGlobalUrl}d/a53530b6-dc80-4f08-9970-a488b745e14b/application-alarm?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&kiosk`
  );

  useEffect(() => {
    props.setBreadcrumb("", "Dashboard", "");
  }, []);

  const handleTimeRange = () => {
    const composedRangeTime = `from=now-${rangeTime}${metric.value}&to=now`;
    const url = `${currentDashboard}&${composedRangeTime}`;
    console.log(url);
    setcurrentDashboard(url);
  };

  return (
    <div className="page">
      <Tabs
        defaultActiveKey="Application Alarm"
        id="sepp-tabs-dashboard"
        className="mb-3"
        justify
      >
        <Tab
          eventKey="Application Alarm"
          title={
            <div
              onClick={() => {
                setcurrentDashboard(
                  `${config.configInfo.grafanaGlobalUrl}d/a53530b6-dc80-4f08-9970-a488b745e14b/application-alarm?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&kiosk&from=now-${rangeTime}${metric.value}&to=now`
                );
              }}
            >
              APPLICATION ALARM
            </div>
          }
        >
          <iframe
            src={currentDashboard}
            style={{ marginTop: "20px" }}
            width="90%"
            height="650px"
            title="Application Alarm"
          />
        </Tab>
        <Tab
          eventKey="Platform Alarm"
          title={
            <div
              onClick={() => {
                setcurrentDashboard(
                  `${config.configInfo.grafanaLocalUrl}d/fb9ab0e1-e2dc-4477-962b-58372ff1f76d/platform-alarm?orgId=1&var-namespace=${namespace}&kiosk&from=now-${rangeTime}${metric.value}&to=now`
                );
              }}
            >
              PLATFORM ALARM
            </div>
          }
        >
          <iframe
            src={currentDashboard}
            style={{ marginTop: "20px" }}
            width="90%"
            height="650px"
            title="Platform Alarm"
          />
        </Tab>
        <Tab
          eventKey="Application Performance"
          title={
            <div
              onClick={() => {
                setcurrentDashboard(
                  `${config.configInfo.grafanaGlobalUrl}d/eee539c6-35ae-4788-9405-83e32e25c1cc/application-performance?orgId=1&var-InstanceName=${config.configInfo.serviceinstance_name}&kiosk&from=now-${rangeTime}${metric.value}&to=now`
                );
              }}
            >
              APPLICATION PERFORMANCE
            </div>
          }
        >
          <iframe
            src={currentDashboard}
            style={{ marginTop: "20px" }}
            width="90%"
            height="650px"
            title="Application Performance"
          />
        </Tab>
        <Tab
          eventKey="Platform Performance"
          title={
            <div
              onClick={() => {
                setcurrentDashboard(
                  `${config.configInfo.grafanaLocalUrl}d/c32a20bb-c2d6-4d92-85ff-3363439d3cd2/platform-performance?orgId=1&var-namespace=${namespace}&kiosk&from=now-${rangeTime}${metric.value}&to=now`
                );
              }}
            >
              PLATFORM PERFORMANCE
            </div>
          }
        >
          <iframe
            src={currentDashboard}
            style={{ marginTop: "20px" }}
            width="90%"
            height="650px"
            title="Platform Performance"
          />
        </Tab>
        <Tab
          eventKey="SEPP Capacity"
          title={
            <div
              onClick={() => {
                setcurrentDashboard(
                  `${config.configInfo.grafanaLocalUrl}d/ff68a132-fbec-4a2b-987d-ab40e57b766c/sepp-capacity?orgId=1&var-namespace=${namespace}&kiosk&from=now-${rangeTime}${metric.value}&to=now`
                );
              }}
            >
              SEPP CAPACITY
            </div>
          }
        >
          <iframe
            src={currentDashboard}
            style={{ marginTop: "20px" }}
            width="90%"
            height="650px"
            title="SEPP Capacity"
          />
        </Tab>
      </Tabs>
      <div className="rowDiv j-center">
        <span style={{ marginRight: "10px", color: "white" }}>Select Last</span>
        <CrossedInput
          size="10%"
          object={rangeTime}
          value={rangeTime || ""}
          placeholder=""
          callback={(e) => setrangeTime(e.target.value)}
        />
        <DropdownButton
          as={ButtonGroup}
          key="primary"
          id={`dropdown-variants-primary`}
          title={metric.label || "Choose one"}
          onSelect={(value) => {
            const _metric = {
              value,
              label:
                value === "m" ? "Minutes" : value === "h" ? "Hours" : "Days",
            };
            setmetric(_metric);
          }}
        >
          <Dropdown.Item active={metric.value === "m"} eventKey="m">
            Minutes
          </Dropdown.Item>
          <Dropdown.Item active={metric.value === "h"} eventKey="h">
            Hours
          </Dropdown.Item>
          <Dropdown.Item active={metric.value === "d"} eventKey="d">
            Days
          </Dropdown.Item>
        </DropdownButton>
        <Button
          style={{ marginLeft: "10px" }}
          onClick={() => handleTimeRange()}
        >
          SHOW
        </Button>
      </div>
    </div>
  );
};

export default Dashboard;
